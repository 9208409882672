<template>
  <MDBCard class="border-0">
    <CategoryTop small :title="mainTitle" :breadcrumbs-parent-routes="['account']"
      :description="$t('staff.dashboard.description')" content-class="px-4 mt-4" left-col="8" right-col="4">
      <template #right>
        <Loader v-if="loaders.lastImport" :show="loaders.lastImport" />
        <DashboardCard v-if="lastImport && !loaders.lastImport">
          <template v-slot:content>
            <div v-html="$t('staff.dashboard.lastImport', {
              date: parseDate(lastImport),
            })
              "></div>
          </template>
        </DashboardCard>
      </template>
    </CategoryTop>
    <MDBRow class="px-4">
      <MDBCol cols="12" md="6" xl="3" class="d-flex">
        <Loader v-if="loaders.overview" :show="loaders.overview" />
        <DashboardCard v-else :title="$t('staff.dashboard.overview.title')"
          :description="$t('staff.dashboard.overview.description')" class="mb-4">
          <template v-if="overview" v-slot:table>
            <MDBTable borderless class="table-dashboard">
              <thead>
                <tr>
                  <th>{{ $t("staff.dashboard.overview.all") }}</th>
                  <th>{{ overview.all }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t("staff.dashboard.overview.month") }}</td>
                  <td>{{ overview.per_month }}</td>
                </tr>
                <tr>
                  <td>{{ $t("staff.dashboard.overview.year") }}</td>
                  <td>{{ overview.per_year }}</td>
                </tr>
              </tbody>
            </MDBTable>
          </template>
        </DashboardCard>
      </MDBCol>
      <MDBCol cols="12" md="6" xl="3" class="d-flex">
        <Loader v-if="loaders.points" :show="loaders.points" />
        <DashboardCard :title="$t('staff.dashboard.credits.title')"
          :description="$t('staff.dashboard.credits.description')" class="mb-4" v-else>
          <template v-if="points" v-slot:table>
            <MDBTable borderless class="table-dashboard">
              <thead>
                <tr>
                  <th>{{ $t("staff.dashboard.credits.prev_month") }}</th>
                  <th>{{ points.prev_month }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ $t("staff.dashboard.credits.month") }}
                  </td>
                  <td>{{ points.month }}</td>
                </tr>
                <tr v-if="isNetherland">
                  <td>
                    {{ $t("staff.dashboard.credits.year") }}
                  </td>
                  <td>{{ points.year }}</td>
                </tr>
                <tr v-if="isNetherland">
                  <td>
                    {{ $t("staff.dashboard.credits.prev_year") }}
                  </td>
                  <td>{{ points.prev_year }}</td>
                </tr>
                <tr v-if="isGermany || isSwitzerland">
                  <td>
                    {{ $t("staff.dashboard.credits.prev_year") }}
                  </td>
                  <td>{{ points.prev_year }}</td>
                </tr>
                <tr v-if="isGermany || isSwitzerland">
                  <td>{{ $t("staff.dashboard.credits.allPoints") }}</td>
                  <td>{{ points.allDealersSumPoints }}</td>
                </tr>
                <tr>
                  <td v-if="isGermany || isSwitzerland">
                    {{
                      isGermany || isSwitzerland
                        ? $t("staff.dashboard.credits.allDealers")
                        : $t("staff.dashboard.overview.all")
                    }}
                  </td>
                  <td v-if="isGermany || isSwitzerland">
                    {{ points.allStaffDealersSumPoints }}
                  </td>
                </tr>
              </tbody>
            </MDBTable>
          </template>
        </DashboardCard>
      </MDBCol>
      <MDBCol cols="12" md="6" xl="3" class="d-flex" v-if="isNetherland">
        <Loader v-if="loaders.kickbacks" :show="loaders.kickbacks" />
        <DashboardCard v-else :title="$t('kickback.title')" :description="$t('staff.dashboard.kickback.description')"
          class="mb-4">
          <template v-if="kickbacks" v-slot:table>
            <MDBTable borderless class="table-dashboard">
              <thead>
                <tr>
                  <th>{{ $t("staff.dashboard.credits.prev_month") }}</th>
                  <th>{{ kickbacks.prev_month }} &euro;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t("staff.dashboard.credits.month") }}</td>
                  <td>{{ kickbacks.month }} &euro;</td>
                </tr>
                <tr>
                  <td>{{ $t("staff.dashboard.credits.year") }}</td>
                  <td>{{ kickbacks.year }} &euro;</td>
                </tr>
                <tr>
                  <td>{{ $t("staff.dashboard.credits.prev_year") }}</td>
                  <td>{{ kickbacks.prev_year }} &euro;</td>
                </tr>
              </tbody>
            </MDBTable>
          </template>
        </DashboardCard>
      </MDBCol>
      <MDBCol cols="12" md="6" xl="3" class="d-flex" v-if="isNetherland">
        <Loader v-if="loaders.tyres" :show="loaders.tyres" />
        <DashboardCard v-else :title="$t('staff.dashboard.tyres.title')"
          :description="$t('staff.dashboard.tyres.description')" class="mb-4">
          <template v-if="tyres" v-slot:table>
            <MDBTable borderless class="table-dashboard">
              <thead>
                <tr>
                  <th>{{ $t("staff.dashboard.credits.prev_month") }}</th>
                  <th>{{ tyres.prevMonth }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t("staff.dashboard.credits.month") }}</td>
                  <td>{{ tyres.currentMonth }}</td>
                </tr>
                <tr>
                  <td>{{ $t("staff.dashboard.credits.year") }}</td>
                  <td>{{ tyres.currentYear }}</td>
                </tr>
                <tr>
                  <td>{{ $t("staff.dashboard.credits.prev_year") }}</td>
                  <td>{{ tyres.prevYear }}</td>
                </tr>
              </tbody>
            </MDBTable>
          </template>
        </DashboardCard>
      </MDBCol>
    </MDBRow>
    <MDBRow class="px-4 pb-4">
      <MDBCol col="12">
        <DashboardCard :title="$t('staff.dashboard.details.title')"
          :description="$t('staff.dashboard.details.description')">
          <template v-slot:search>
            <div class="d-flex flex-column justify-content-end align-items-end">
              <div class="w-100">
                <MDBInput v-model="query" :label="$t('search')" />
              </div>
              <div>
                <MDBBtn color="danger" @click="search">{{
                  $t("buttons.toSearch")
                }}</MDBBtn>
              </div>
            </div>
          </template>
          <template v-slot:table>
            <div class="overflow-x-auto">
              <MDBTable borderless class="table-dashboard">
                <thead>
                  <tr>
                    <th v-for="(head, id) in detailsHeaders" :key="`key_${id}`" :class="`${head.show ? '' : 'd-none'}`">
                      <div class="table-header-stats">
                        <span>{{ head.label }}</span>
                        <img v-if="head.field != 'details'" :src="require('@/assets/img/icons/filter_btn.svg')"
                          alt="filter_btn" class="icon-container--small cursor--pointer" @click="newSort(head.field)" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, key) in users" :key="`user_${key}`" class="cursor--pointer staffWsh-table-row">
                    <template v-for="(prop, val) in user" :key="`item_${val}`">
                      <td>
                        <div class="loader" v-if="loaders.details && selectedIndex == key"></div>
                        <div @click="openDetailsModal(key, user)" v-else>
                          <template v-if="val == 'details'">
                            <div class="text-decoration-underline cursor--pointer">
                              {{ $t("profile.details") }}
                            </div>
                          </template>
                          <template v-else>
                            {{ prop }}
                          </template>
                        </div>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </MDBTable>
            </div>
            <div class="load-more d-flex justify-content-center">
              <button @click="loadMore" class="btn btn-danger text-uppercase px-5" :disabled="loading" type="button">
                {{ $t("wholesaler.dashboard.loadMore") }}
              </button>
              <button @click="loadAll" class="btn btn-danger text-uppercase px-5" v-if="isNetherland"
                :disabled="loading" type="button">
                {{ $t("buttons.all") }}
              </button>

              <button @click="openDownloadProfileModal" class="btn btn-danger text-uppercase px-5" v-if="isNetherland"
                :disabled="loading" type="button">
                {{ $t("buttons.dealerInfoDownload") }}
              </button>

              <downloadexcel v-if="isGermany" class="btn btn-danger text-uppercase px-5" :fetch="loadAll" :data="users"
                :fields="exportLabels" type="xlsx" name="stats.xlsx">
                Download Excel
              </downloadexcel>
            </div>
          </template>
        </DashboardCard>
      </MDBCol>
    </MDBRow>
  </MDBCard>
  <MDBModal v-model="modal" tabindex="-1" centered class="modal-xxl" staticBackdrop>
    <div class="d-flex justify-content-end px-3 py-3 "><span class="cursor--pointer" @click="closeDetails()">X</span>
    </div>
    <PointsOverviewModal v-if="detailsId && userDetails" :user_id="detailsId" :user_data="userDetails"
      :profile_data="selectedProfile" :wsh_data="wshSelect" />
  </MDBModal>
  <MDBModal v-model="profileModal" tabindex="-1" centered class="modal-xxl">
    <ProfileDownloadModal @close="openDownloadProfileModal()" />
  </MDBModal>
</template>

<script setup>
import {
  MDBCard,
  MDBCol,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBTable,
  MDBModal,
} from "mdb-vue-ui-kit";
import downloadexcel from "vue-json-excel3";

import { useStore } from "vuex";
import { ref, computed, toRaw, onMounted } from "vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import i18n from "@/plugins/i18n";

import { ProfileService } from "@/services/profile.service";
import { WholesalerService } from "@/services/wholesaler.service";
import { StaffService } from "@/services/staff.service";

import CategoryTop from "@/components/General/CategoryTop/index.vue";
import DashboardCard from "@/components/User/Seller/DashboardCard.vue";
import PointsOverviewModal from "./PointsOverviewModal.vue";
import ProfileDownloadModal from "./ProfileDownloadModal.vue";
import Loader from "@/components/General/Loader/index.vue";
import dates from "@/plugins/dates";

const store = useStore();
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const isNetherland = computed(() => LANG === "nl");
const isSwitzerland = computed(() => LANG === "ch");

const me = computed(() => store.getters.getMe);
const fullName = ref("");
const selectedIndex = ref(null)

const loaders = ref({
  overview: true,
  points: true,
  kickbacks: true,
  lastImport: true,
  tyres: true,
  details: false
});

const mainTitle = computed(() => {
  if (isNetherland.value) {
    return fullName.value + " - dashboard";
  } else {
    return i18n.global.t("staff.dashboard.title");
  }
});
const allDealersNumber = computed(() => store.getters.getAllDealersNumber);
const loading = computed(() => store.getters.getPending);

const sortData = ref({
  field: "",
  order: "asc",
});

const page = ref(0);
const users = ref([]);
const query = ref("");
const overview = ref(null);
const points = ref(null);
const lastImport = ref(null);
const kickbacks = ref(null);
const tyres = ref(null);
const modal = ref(false);
const profileModal = ref(false);
const detailsId = ref(null);
const userDetails = ref(null);
const selectedProfile = ref(null);
const wshSelect = ref(null);
const exportLabels = ref([]);

const detailsHeaders = ref([]);

const search = async () => {
  const usersResponse = await StaffService.staffSearch(query.value);
  users.value = usersResponse.body;
  detailsHeaders.value = usersResponse.headers;
};

const parseDate = (date) => {
  return dates.parseDate(date);
};

const openDetailsModal = async (key, user) => {
  loaders.value.details = true
  selectedIndex.value = key
  detailsId.value = user.details;
  userDetails.value = user;
  const response = await ProfileService.userProfileData(user.details);
  selectedProfile.value = response.data.data;
  if (isNetherland.value) {
    const bonusData = { user_id: selectedProfile.value.user_id }
    await ProfileService.createBonus(bonusData);
  }

  const selectData = await WholesalerService.wholesalerSelect();

  wshSelect.value = selectData.data;

  await store.dispatch("loadPointsByCsNumber", user.details);
  modal.value = true;
  loaders.value.details = false
};

const newSort = (field) => {
  if (sortData.value.field == field) {
    sortData.value.order = sortData.value.order === "asc" ? "desc" : "asc";
  } else {
    sortData.value.order = "asc";
  }
  sortData.value.field = field;
  users.value.sort(dates.compareSortValues(sortData.value.field, sortData.value.order));
};

const loadMore = async () => {
  page.value += 1;

  const usersResponse = await StaffService.staffSearch(query.value, page.value);

  if (usersResponse) {
    users.value.push(...Object.values(toRaw(usersResponse.body)));
    newSort(sortData.value.field);
  }
};

const loadAll = async () => {
  query.value = "";
  toast(i18n.global.t("toastMessages.waitMoment"), {
    type: "success",
    theme: "colored",
    transition: toast.TRANSITIONS.SLIDE,
    position: toast.POSITION.BOTTOM_RIGHT,
  });
  users.value = [];
  page.value = -1;

  const attempts = allDealersNumber.value / 20;

  for (let i = 0; i < attempts; i++) {
    await loadMore();
  }

  toast(i18n.global.t("toastMessages.downloadFinished"), {
    type: "success",
    theme: "colored",
    transition: toast.TRANSITIONS.SLIDE,
    position: toast.POSITION.BOTTOM_RIGHT,
  });

  return users.value;
};

const openDownloadProfileModal = () => {
  profileModal.value = !profileModal.value;
};

const closeDetails = () => {
  selectedIndex.value = null
  detailsId.value = null;
  userDetails.value = null;
  modal.value = false;
};

onMounted(async () => {
  const profileResponse = await ProfileService.userProfileData(me.value.id);
  fullName.value =
    profileResponse.data.data.forename + " " + profileResponse.data.data.name;
  const usersResponse = await StaffService.staffSearch();
  users.value = usersResponse.body;
  detailsHeaders.value = usersResponse.headers;

  exportLabels.value = detailsHeaders.value.reduce((acc, current) => {
    // Ensure 'field' property exists and has a value
    if (current.field) {
      acc[current.label] = current.field;
    } else {
      console.warn(`Skipping object without 'field' property:`, current);
    }
    return acc;
  }, {});

  overview.value = await StaffService.staffOverview(me.value.id);
  loaders.value.overview = false;
  lastImport.value = await StaffService.staffLastImport(me.value.id);
  loaders.value.lastImport = false;
  points.value = await StaffService.staffPoints(me.value.id);
  loaders.value.points = false;

  store.dispatch("addAllDealersNumber", overview.value.all);

  if (isNetherland.value) {
    kickbacks.value = await StaffService.staffKickbacks(me.value.id);
    loaders.value.kickbacks = false;
    tyres.value = await StaffService.staffTyres(me.value.id);
    loaders.value.tyres = false;
  }
});
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
@import "@/styles/table_dashboard.scss";

.pointer {
  cursor: pointer;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #000 90%, #0000) 0/calc(100%/3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0)
  }
}
</style>
