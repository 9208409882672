<template>
  <CategoryTop :title="$t('commitmentLetter.title')" />
  <MDBRow>
    <MDBCol col="12" class="pb-3">
      <component :is="activeContentComponent" />
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBCol, MDBRow } from "mdb-vue-ui-kit";

import { computed } from "vue";
import ContentNl from "@/components/CommitmentLetter/ContentNl.vue";
import ContentDe from "@/components/CommitmentLetter/ContentDe.vue";
import CategoryTop from "@/components/General/CategoryTop/index.vue";

const LANG = localStorage.getItem("lang");

// Define a computed property to determine the active content component
const activeContentComponent = computed(() => {
  const data = {
    de: ContentDe,
    nl: ContentNl
  }

  return data[LANG];
});
</script>
