<template>
    <MDBRow>
        <MDBCol xs="12">
            <div class="fw-bold">Bonus multiplicator</div>
            <div class="pb-2">{{ $t('multiplicator.selectTarget', { year: currentYear }) }}</div>
            <MDBTable borderless v-if="bonusMultiplicator.length > 0" class="points-overview px-1">
                <thead>
                    <th width="300">&nbsp;</th>
                    <th v-for="(bonus, index) in bonusMultiplicator" :key="index"
                        :class="{ 'bg-green': user.bonus_multiplicator_id == bonus.id }" class="cursor--pointer"
                        @click="storeUserGoal(bonus.id)">
                    <td class="fw-bold"> {{ $t(`multiplicator.goals.${bonus.name}`) }}</td>
                    </th>
                </thead>
                <tbody>
                    <tr>
                        <td class="fw-bold">{{ $t('tyreBox.minimum') }}</td>
                        <td colspan="5" class="text-center">{{ $t('multiplicator.minTyres', {
                            tyre: shopLimit?.limit
                        }) }}</td>
                    </tr>
                    <tr>
                        <td class="fw-bold">{{ $t('multiplicator.amountLabel') }}</td>
                        <td v-for="(bonus, index1) in bonusMultiplicator" :key="index1" @click="storeUserGoal(bonus.id)"
                            :class="{ 'bg-green': user.bonus_multiplicator_id == bonus.id }" class="cursor--pointer">{{
                                bonus.min_tyres }} - {{
                                bonus.show_max ? bonus.max_tyres : $t('multiplicator.andMore')
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="fw-bold">{{ $t('multiplicator.label') }}</td>
                        <td v-for="(bonus, index2) in bonusMultiplicator" :key="index2"
                            :class="{ 'bg-green': user.bonus_multiplicator_id == bonus.id }" class="cursor--pointer">x
                            {{
                                bonus.factor }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6" class="fw-bold">
                            Bonus per band na multiplicator
                        </td>
                    </tr>
                    <tr>
                        <td>PC4 Bridgestone 14"-16"</td>
                        <td>1,00 &euro;</td>
                        <td>1,25 &euro;</td>
                        <td>1,50 &euro;</td>
                        <td>1,75 &euro;</td>
                        <td>2,00 &euro;</td>
                    </tr>
                    <tr>
                        <td>PC4 Bridgestone 17"-18"</td>
                        <td>2,00 &euro;</td>
                        <td>2,50 &euro;</td>
                        <td>3,00 &euro;</td>
                        <td>3,50 &euro;</td>
                        <td>4,00 &euro;</td>
                    </tr>
                    <tr>
                        <td>PC4 Bridgestone 19"-22"</td>
                        <td>4,00 &euro;</td>
                        <td>5,00 &euro;</td>
                        <td>6,00 &euro;</td>
                        <td>7,00 &euro;</td>
                        <td>8,00 &euro;</td>
                    </tr>
                </tbody>
            </MDBTable>
            <div>(*) {{ $t('multiplicator.desc') }}</div>
        </MDBCol>
    </MDBRow>
</template>

<script setup>
import { MDBCol, MDBRow, MDBTable } from "mdb-vue-ui-kit";
import { ProfileService } from "@/services/profile.service";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();
const currentYear = new Date().getFullYear()

const user = computed(() => store.getters.getMe);
const bonusMultiplicator = computed(() => store.getters.getBonusMultiplicator);
const shopLimit = computed(() => store.getters.getTyreLimit);

const storeUserGoal = async (bonus_multiplicator_id) => {
    const data = {
        user_id: user.value.plainId,
        bonus_multiplicator_id: bonus_multiplicator_id
    }
    await ProfileService.setTyreGoal(data)
    await store.dispatch("me");
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
@import "@/styles/points_overview.scss";

.simple-table {
    width: 100%;
    border-spacing: 1px;
    border-collapse: separate;
    padding-top: 30px;

    td,
    th {
        padding: 5px;
        text-align: left;
    }

    th {
        background: $gray;
        color: $backgroundGray;
    }
}

.simple-table>tbody:nth-of-type(odd)>* {
    background-color: $secondaryGray;
}

.simple-table>tbody:nth-of-type(even)>* {
    background-color: $backgroundGray;
}

.th--height {
    height: 56px;
    vertical-align: top;
}

.td--width {
    width: 150px;
}
</style>
