<template>
    <div>
        <p>Algemene voorwaarden van Bridgestone Europe NV/SA, Netherlands Branch, gevestigd te Nieuwe Weideweg 1 NL-6121
            PD
            Born (Nederland) - K.v.K. 66469767 (hierna te noemen: "Bridgestone").</p>
        <p>De huidige deelnamevoorwaarden, van kracht vanaf 1 januari 2025, treden in de plaats van en herroepen alle
            voorgaande versies. Deze voorwaarden zijn exclusief van toepassing op deelname vanaf de datum van
            publicatie,
            tenzij uitdrukkelijk anders is bepaald door Bridgestone.”</p>
        <ol>
            <li data-list-text="1.">
                <h6>DEELNAME</h6>

                <p>De Algemene voorwaarden houden voor de deelnemer geen verplichting in om een door Bridgestone
                    vastgesteld
                    marktaandeel te bereiken en ze verplichten de deelnemer evenmin uitsluitend Bridgestone-banden te
                    verkopen.
                </p>
                <p>Overeengekomen is dat de deelnemer vrij blijft om zijn eigen verkoopprijzen te bepalen voor banden,
                    zonder
                    beperkingen, ook als er aanbevelingen zijn; deze worden echter als niet-bindend beschouwd.</p>
                <p>De vereisten voor deelname aan Bridgestone PLUS die bindend zijn voor het deelnemende bedrijf waarmee
                    de
                    overeenkomst is gesloten (hierna te noemen: “<b>deelnemer</b>”). De deelnemer erkent dat toelating
                    voor
                    deelname aan Bridgestone PLUS gebeurt naar goeddunken van Bridgestone en dat eisen tot toelating
                    tegen
                    Bridgestone niet geldig zijn. De overdracht van deelnemersaccounts is uitdrukkelijk verboden, tenzij
                    Bridgestone anders heeft aangegeven. Het is niet toegestaan een pandrecht te vestigen op het
                    deelnemersaccount.</p>
                <p>Deelnemers zijn verantwoordelijk voor het direct bijwerken van hun online persoonsgegevens. Niet-
                    naleving
                    van deze verplichting kan leiden tot extra kosten, vertragingen, grotere kans op schade of het
                    mislopen van
                    prijzen en andere bijkomende schade.</p>
            </li>
            <li data-list-text="2.">
                <h6>BONUS EN MARKETINGPUNTEN</h6>
                <p>Het recht op uitbetaling ontstaat wanneer de deelnemer tussen 1 januari en 31 december ten minste
                    honderdtwintig (120) banden van het merk Bridgestone voor koopt bij de geselecteerde distributeurs.
                    Wanneer
                    het minimumaantal per jaar niet wordt bereikt, wordt er geen bonus verleend aan de deelnemer.</p>
                <p>Voor de berekening van marketing punten geldt de relevante boekingsperiode januari-december van elk
                    jaar.</p>
                <p>De bonus en marketingpunten gelden niet voor 2A-artikelen, demobanden, niet-actieve/inactieve EAN's
                    op de
                    prijslijst. Ongebruikte punten uit een voorgaand jaar vervallen in maart van het jaar erop.</p>
                <p>De marketingpunten worden aan de deelnemer uitsluitend gecrediteerd op initiatief van Bridgestone en
                    volledig
                    in overeenstemming met de voorwaarden die zijn vermeld in het tabblad Voorwaarden op het Bridgestone
                    PLUS-platform. Het aantal punten per type band is te vinden op het Bridgestone PLUS- platform.
                    https://plus-nl.bridgestone.eu/</p>
            </li>
            <li data-list-text="3.">
                <h6>BELONINGEN</h6>
                <ol>
                    <li data-list-text="3.1">
                        <p>Marketingpunten</p>
                        <p>De deelnemer heeft een medewerker of gedelegeerde aangewezen om de beloningen namens hem te
                            ontvangen.
                            Deze persoon heeft toegang tot het puntenaccount van de deelnemer.</p>
                        <p>Deelnemers kunnen ervoor kiezen de bijgeschreven punten in te wisselen voor aangeboden
                            beloningen in
                            natura en/of diensten. Uitbetaling van de contante waarde van de gespaarde punten is niet
                            toegestaan,
                            tenzij dit uitdrukkelijk is vermeld in het beloningssysteem. Er wordt geen rente opgebouwd
                            op het
                            deelnemersaccount. Punten kunnen worden ingewisseld als op het puntenaccount voldoende
                            punten staan voor
                            de specifieke beloning. Deelnemers hebben geen gegarandeerd recht op een bepaalde beloning
                            en inwisseling
                            is onder voorbehoud van beschikbaarheid.</p>
                        <p>Bridgestone behoudt zich het recht voor overduidelijk onjuist geprijsde beloningen te
                            corrigeren.
                            Ingewisselde punten zijn niet overdraagbaar en kunnen niet worden geruild voor extra punten
                            of andere
                            beloningen. De deelnemer ontvangt direct na het plaatsen van de order een
                            ontvangstbevestiging.
                            Bridgestone zal daarna direct alle relevante gegevens en de beschikbaarheid van de beloning
                            controleren.
                            Tenzij uitdrukkelijk anders is vermeld zijn alle specificaties over prestaties, afmetingen,
                            gewicht en
                            formaat bij benadering; deze worden uitsluitend ter indicatie opgegeven en vormen geen
                            gegarandeerde
                            eigenschappen. De levertijden worden aan de deelnemer afzonderlijk doorgegeven na het
                            plaatsen van de
                            order. In het geval dat het bestelde artikel niet beschikbaar is of niet kan worden
                            geleverd, behoudt
                            Bridgestone het recht de deelnemer een vergelijkbaar artikel te sturen of de order te
                            annuleren.</p>
                        <p>De marketingpunten moeten door de deelnemer uiterlijk 31 maart van het volgende jaar worden
                            gebruikt,
                            anders vervallen ze.</p>
                    </li>
                    <li data-list-text="3.2">
                        <p>Bonus</p>
                        <p>De deelnemer ontvangt een bonus zodra de aangegane verplichtingen zijn bereikt bij de
                            geselecteerde
                            distribiteurs.</p>
                        <p>De bonus wordt opgebouwd wanneer de voorwaarden voor deelname zijn geaccepteerd door de
                            deelnemer door
                            bandenorders die mogelijk worden gemaakt door distributeurs die Bridgestone heeft
                            aangewezen. Bridgestone
                            of de geselecteerde dienstverlener keert de bonus eenmaal per jaar uit aan de deelnemer na
                            een controle
                            van de juistheid van de verzamelde gegevens en mits is voldaan aan de hiervoor vermelde
                            verplichtingen.
                            Deze uitkering vindt plaats in het eerste kwartaal van het volgende jaar.</p>
                        <p>Dit proces verloopt automatisch na een uitgebreide beoordeling van de bandenorders van de
                            deelnemer en de
                            bijbehorende gegevens. De deelnemer ontvangt de bonus rechtstreeks van Bridgestone of van
                            een
                            geselecteerde dienstverlener. Tegelijkertijd ontvangt de deelnemer een document bestemd voor
                            zijn
                            boekhouding. Bridgestone behoudt zich uitdrukkelijk het recht voor om correcties aan te
                            brengen in geval
                            van technische fouten, rekenfouten of onjuiste gegevens.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="4.">
                <h6>PUNTENAANTAL / WACHTWOORD</h6>
                <p>De punten worden geregistreerd op het nummer van de deelnemer. Klachten over de juistheid of
                    volledigheid van
                    het puntenaantal moeten schriftelijk worden ingediend binnen een maand nadat dit geconstateerd is.
                    Het niet
                    tijdig indienen van een klacht wordt beschouwd als goedkeuring van het puntenaantal. Ook na het
                    verstrijken
                    van de termijn kan worden verzocht om correctie van het</p>
                <p>vermelde puntenaantal als de deelnemer de onjuistheid/onvolledigheid onomstotelijk kan aantonen. De
                    deelnemer
                    is verantwoordelijk voor waarborging van de vertrouwelijkheid van zijn wachtwoord en wanneer hij
                    vermoedt dat
                    iemand anders kennis heeft gekregen van het wachtwoord of in geval van ander misbruik moet de
                    deelnemer het
                    wachtwoord meteen wijzigen en Bridgestone op de hoogte stellen.</p>
            </li>
            <li data-list-text="5.">
                <h6>GEBREKEN/TEKORTEN</h6>
                <p>Gebreken in de geleverde artikelen moeten onmiddellijk schriftelijk worden gemeld. Wanneer dit niet
                    gebeurt,
                    gelden de wettelijke bepalingen.</p>
            </li>
            <li data-list-text="6.">
                <h6>BEËINDIGING</h6>
                <p>Deelname aan Bridgestone PLUS is vrijwillig en deelnemers kunnen hun deelname op elk moment
                    beëindigen door
                    Bridgestone daarvan op de hoogte te stellen. Het is van belang erop te wijzen dat beëindiging van de
                    deelname
                    geen gevolgen heeft voor het inwisselen van gespaarde punten. Bridgestone behoudt het recht om een
                    deelnemer
                    om gegronde redenen uit te sluiten van het programma.</p>
            </li>
            <li data-list-text="7.">
                <h6>AANSPRAKELIJKHEID</h6>
                <p>De deelnemer aanvaardt voor zijn risico alle verplichtingen in verband met zijn bedrijfsorganisatie,
                    bijvoorbeeld met betrekking tot werkgeverschap en relaties met zelfstandigen, met inbegrip van
                    verzekeringsverplichtingen, het juiste fiscale en administratieve beheer van het bedrijf en de
                    naleving van de
                    geldende regelgeving met betrekking tot milieu, veiligheid en ongevalspreventie.</p>
                <p>Behalve in geval van opzettelijk wangedrag of grove nalatigheid is Bridgestone niet aansprakelijk
                    voor door
                    de deelnemer veroorzaakte schade bij de uitvoering van de in de voorwaarden bedoelde activiteiten.
                </p>
            </li>
            <li data-list-text="8.">
                <h6>WIJZIGING VAN DE VOORWAARDEN VOOR DEELNAME EN BESTELLEN</h6>
                <p>Bridgestone behoudt zich het recht voor het beloningssysteem stop te zetten met inachtneming van een
                    opzegtermijn van 3 maanden; indien daarvoor een belangrijke reden bestaat ook zonder inachtneming
                    van een
                    opzegtermijn, mits de belangen van de deelnemer redelijk gewaarborgd zijn. Bridgestone behoudt zich
                    ook het
                    recht voor de voorwaarden te wijzigen of aan te vullen voor zover dit noodzakelijk is in het belang
                    van een
                    eenvoudigere en veiligere verwerking en met name ter voorkoming van misbruik. De deelnemer wordt van
                    tevoren
                    geïnformeerd over wijzigingen. Een wijziging en/of aanvulling wordt geacht te zijn goedgekeurd
                    wanneer deze
                    niet binnen een maand na bekendmaking wordt beëindigd of wanneer de deelnemer opnieuw gebruikmaakt
                    van het
                    beloningsprogramma nadat deze periode is verstreken.</p>
            </li>
            <li data-list-text="9.">
                <h6>VERTROUWELIJKHEID</h6>
                <p>De deelnemer verbindt zich voor hemzelf en voor zijn werknemers en medewerkers om informatie van
                    vertrouwelijke aard zoals technische informatie, advertentie- of financiële gegevens, commerciële
                    informatie,
                    knowhow, prijzen, kosten, administratieve gegevens, lopende en toekomstige operationele projecten,
                    strategieën
                    van Bridgestone of andere informatie die op het moment van bekendmaking nog niet openbaar is niet te
                    verspreiden aan derde partijen en deze niet te gebruiken. Deze bepaling moet ook als bindend worden
                    beschouwd
                    gedurende de vijf jaar volgend op de beëindiging van de deelname.</p>
            </li>
            <li data-list-text="10.">
                <h6>GEGEVENSBESCHERMING</h6>
                <p>Bridgestone, haar geselecteerde dienstverlener en de deelnemer verbinden zich tot naleving van alle
                    verplichtingen op grond van Verordening (EU) 2016/679 van het Europees Parlement en de Raad van 27
                    april 2016
                    betreffende de “bescherming van natuurlijke personen in verband met de verwerking van
                    persoonsgegevens en
                    betreffende het vrije verkeer van die gegevens” (hierna: “AVG”), evenals de relevante nationale
                    regelgeving en
                    de maatregelen van de toezichthoudende autoriteit.</p>
                <p>Om deel te kunnen nemen aan Bridgestone PLUS moeten er verschillende persoonsgegevens worden
                    verzameld,
                    opgeslagen en gebruikt. Deze gegevens zijn nodig om een persoonlijk puntenaccount te openen voor de
                    deelnemer
                    en hem informatie over het beloningsprogramma te kunnen sturen. De vereiste gegevens voor deelname
                    bestaan
                    uit: bedrijfsnaam, achternaam, voornaam, functie bij het bedrijf, volledig postadres,
                    telefoonnummer,
                    btw-nummer, bankrekeningnummer en e-mailadres. Ten behoeve van de verwerking en uitvoering van
                    Bridgestone
                    PLUS worden de gegevens van de deelnemer opgeslagen en gebruikt door TAKTZEIT, Bridgestone of een
                    dienstverlenend bedrijf dat handelt in opdracht van TAKTZEIT. Bridgestone en TAKTZEIT verbinden zich
                    ook om de
                    wet- en regelgeving inzake gegevensbescherming na te leven. Gegevens worden alleen aan derde
                    partijen
                    verstrekt als deze handelen als opdrachtnemer, bijvoorbeeld voor de verwerking van een order voor
                    een
                    beloning. Door deelname aan het beloningsprogramma verleent de deelnemer toestemming hiervoor door
                    dit te
                    bevestigen. Deze toestemming kan op elk moment schriftelijk worden ingetrokken. Na de
                    toestemmingsverklaring
                    ontvangt de deelnemer de desbetreffende nieuwsbrief. Afmelding voor een nieuwsbrief is op elk moment
                    mogelijk
                    via het menu-onderdeel “Contact &gt; Afmelden nieuwsbrief”</p>
            </li>
            <li data-list-text="11.">
                <h6>Intellectuele eigendom</h6>
                <p>De deelnemer erkent en
                    aanvaardt dat alle huidige en toekomstige intellectuele-eigendomsrechten (hierna: “IE-rechten”) met
                    betrekking
                    tot, inherent aan of op enige wijze verband houdend met Bridgestone PLUS eigendom zijn van de
                    Bridgestone-groep. De deelnemer mag IE-rechten uitsluiten gebruiken in verband met zijn
                    bedrijfsactiviteiten
                    in de vorm van de verkoop van banden en het verlenen van diensten.</p>
                <p>In- en externe projecten voor erkenning van verkooppunten moeten worden goedgekeurd door Bridgestone.
                    Overeengekomen is dat wanneer de deelnemer van plan is de IE-rechten te gebruiken in enige vorm van
                    communicatie met het publiek, het promotie- en advertentiemateriaal dat is voorbereid voor dit
                    doeleinde van
                    tevoren aan Bridgestone moet worden voorgelegd ter goedkeuring. Bridgestone heeft het recht om op
                    elk moment
                    door middel van een inspectie ter plaatse in het pand van de deelnemer na te gaan of de regels voor
                    het
                    gebruik van IE-rechten in acht zijn genomen.</p>
                <p>De deelnemer mag het gebruik van IE-rechten op geen enkele wijze overdragen en/of op andere wijze
                    daarvan
                    afstand doen aan derde partijen.</p>
            </li>
            <li data-list-text="12.">
                <h6>SLOTBEPALINGEN</h6>
                <ol>
                    <li data-list-text="12.1">
                        <p>Wijzigingen in of toevoegingen aan deze voorwaarden moeten schriftelijk worden vastgelegd.
                            Dit geldt ook
                            voor het afstand nemen van deze schriftelijkheidsvereiste.</p>
                    </li>
                    <li data-list-text="12.2">
                        <p>Het Nederlandse recht is van toepassing op de uitsluiting van het Verdrag der Verenigde
                            Naties inzake
                            internationale koopovereenkomsten betreffende roerende zaken. Voor geschillen in verband met
                            of als gevolg
                            van de verbintenisbrief wijzen partijen de rechtbank te Rotterdam (Nederland) aan als het
                            uitsluitend
                            bevoegde gerecht.</p>
                    </li>
                    <li data-list-text="12.3">
                        <p>Wanneer afzonderlijke bepalingen uit deze voorwaarden niet-uitvoerbaar of strijdig met de
                            wettelijke
                            bepalingen heeft dit geen gevolgen voor de rest van de overeenkomst. De ongeldige bepaling
                            wordt door de
                            contractpartijen in onderling overleg vervangen door een</p>
                        <p>
                            bepaling die in economisch opzicht de ongeldige bepaling zo dicht mogelijk benadert op een
                            juridisch
                            effectieve wijze.</p>
                    </li>
                    <li data-list-text="12.4">
                        <p>De voorgaande bepaling geldt dienovereenkomstig in geval van lacunes of omissies. Mondelinge
                            overeenkomsten worden als ongeldig beschouwd en alleen de voorwaarden die zijn vermeld in de
                            Algemene
                            voorwaarden (AV) worden als bindend beschouwd.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="13.">
                <h6>RETOURBELEID</h6>
            </li>
        </ol>
        <p>De deelnemer heeft gedurende 14 dagen recht op retourzending.</p>
        <p>In dat geval moet deze zich schriftelijk wenden tot:
            Touch Incentive Rotterdam – 010-2043075 –
            <a href="mailto:support@touchincentive.com" target="_blank">support@touchincentive.com</a> <span>. Touch
                Incentive
                verstrekt aan de
                deelnemer de nodige aanwijzingen voor het retourproces. De retourtermijn gaat in zodra de deelnemer de
                goederen
                ontvangt. In geval van een retourzending van een geleverd product is de koper verantwoordelijk voor de
                betaling
                van de retourkosten als de geleverde goederen overeenstemmen met de bestelde artikelen. De punten die de
                deelnemer heeft ingewisseld voor de beloning worden in mindering gebracht op het puntenaccount. Het
                recht op
                retourzending geldt niet voor artikelen die op maat zijn gemaakt volgens specificaties of duidelijk zijn
                aangepast aan persoonlijke eisen.</span>
        </p>
    </div>
</template>

<script setup></script>