<template>
  <MDBRow>
    <MDBCol col="12" md="6" xl="4" v-for="(data, i) in supportData" :key="i" :class="`${data.show ? '' : 'd-none'}`">
      <Card class="mb-4" :data="data" :linkText="data.linkText" minHeight="container-height" v-if="data.show" />
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed } from "vue";
import Card from "@/components/General/Card/index.vue";
import i18n from "@/plugins/i18n";
import TokenService from "@/services/storage.service";
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");

const supportData = [
  {
    name: i18n.global.t("salesSupport.salesPromotions.title"),
    link: { name: "salesPromotions" },
    description: i18n.global.t("salesSupport.salesPromotions.desc"),
    image: require("@/assets/img/sales_promotions_2025.jpg"),
    linkText: i18n.global.t("buttons.discoverButton"),
    show: true,
  },
  {
    name: i18n.global.t("salesSupport.displaysFurniture.title"),
    link: { name: "shop" },
    description: i18n.global.t("salesSupport.displaysFurniture.desc"),
    image: require("@/assets/img/disaply_furniture.png"),
    linkText: i18n.global.t("dashboard.showAllProducts"),
    show: TokenService.showElement("displaysFurniture.see"),
  },
  {
    name: i18n.global.t("salesSupport.individualSupport.title"),
    link: { name: "shop" },
    description: i18n.global.t("salesSupport.individualSupport.desc"),
    image: require("@/assets/img/individual_support.png"),
    linkText: i18n.global.t("dashboard.showAllProducts"),
    show: TokenService.showElement("individualSupport.see"),
  },
  {
    name: i18n.global.t("onlineVisibility.box.title"),
    link: { name: "onlineVisibility" },
    description: i18n.global.t("onlineVisibility.description"),
    image: require("@/assets/img/uberall-logo.jpg"),
    linkText: i18n.global.t("buttons.discoverButton"),
    show: TokenService.showElement("uberall.see"),
  },

  {
    name: i18n.global.t("productInfo.index.categories.calculator.title"),
    link: { name: "fuelCalculator" },
    description: i18n.global.t(
      "productInfo.index.categories.calculator.description"
    ),
    image: require("@/assets/img/fuelCalc.png"),
    show: isGermany.value,
    simpleLink: false,
  },
];
</script>
