<template>
    <VeeForm class="form user-form" @submit="executeVoucher">
        <MDBCard>
            <div class="py-5 px-5">
                <h1 v-html="$t('vouchers.exchange.title')"></h1>
                <MDBRow>
                    <MDBCol cols="12" md="6">
                        <div class="pt-2">
                            <Field v-model="code" type="text" class="form-control" aria-describedby="basic-addon1"
                                @change="clearPoints()" :rules="{ required: true }" name="code"
                                :label="$t('vouchers.exchange.pointsLabel')" />

                            <ErrorMessage name="code" class="text-danger text-small text-end m-0 pt-1" />
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow v-if="points" class="pt-3">
                    <MDBCol cols="12">
                        <div class="text-center">{{ $t('vouchers.exchange.result') }}</div>
                        <div class="text-success fw-bold font-20 text-center text-lowercase">{{ points }}</div>
                    </MDBCol>
                </MDBRow>

                <div class="pt-4">
                    <MDBBtn color="primary" @click="closeModal()">{{ $t("buttons.cancel") }}
                    </MDBBtn>
                    <MDBBtn color="danger" type="submit">{{ $t("buttons.confirm") }}</MDBBtn>
                </div>
            </div>
        </MDBCard>
    </VeeForm>
</template>

<script setup>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { MDBCard, MDBBtn, MDBCol, MDBRow } from "mdb-vue-ui-kit";

import { ref, defineEmits, computed } from "vue";
import i18n from "@/plugins/i18n";

import { useStore } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { StaffService } from "@/services/staff.service";
import { UserService } from "@/services/user.service";

const store = useStore();

const user = computed(() => store.getters.getMe);
const code = ref(null)
const points = ref(null)
const voucherId = ref(null)

const emit = defineEmits(["close"]);
const clearPoints = () => {
    points.value = null
    voucherId.value = null
}

const executeVoucher = async () => {
    if (voucherId.value) {
        const data = {
            code: code.value,
            userId: user.value.plainId
        }
        const response = await UserService.voucherUse(data)

        if (response && response.status == 404) {
            toast(i18n.global.t('toastMessages.noVoucher'), {
                type: "error",
                theme: "colored",
                transition: toast.TRANSITIONS.SLIDE,
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } else {
            window.location.reload()
        }
    } else {
        clearPoints()
        const response = await StaffService.voucherInfo(code.value)

        if (response && response.voucher) {
            points.value = response.voucher.points
            voucherId.value = response.voucher.id
        } else {
            toast(i18n.global.t('toastMessages.noVoucher'), {
                type: "error",
                theme: "colored",
                transition: toast.TRANSITIONS.SLIDE,
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }
}

const closeModal = () => {
    emit("close", false);
};
</script>