<template>
  <Loader :show="loading" v-if="loading" />
  <div v-if="content.length > 0 && !loading">
    <MDBRow class="mb-3" v-for="(item, index) in content" :key="index">
      <MDBCol col="12">
        <div class="sub-title text-uppercase">
          <span class="text-uppercase text-danger">/ {{ index + 1 }} <span class="">PLUS:</span></span>&nbsp;<span
            class="text-dark-gray">{{
              item.title[LANG]
            }}</span>
        </div>

        <div v-html="item.description[LANG]"></div>
        <div v-if="!Array.isArray(item.action_link) && !Array.isArray(item.action_text)"
          class="d-flex justify-content-center align-items-center">
          <a class="btn btn-danger text-uppercase text-white" :href="item.action_link[LANG]">{{ item.action_text[LANG]
            }}</a>
        </div>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import Loader from "@/components/General/Loader/index.vue";
import { computed } from "vue";
const LANG = localStorage.getItem("lang");
const store = useStore();

store.dispatch("loadAboutPage");

const content = computed(() => store.getters.getAboutPage)
const loading = computed(() => store.getters.getPending);
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.sub-title {
  font-size: 20px;
  font-weight: 700;
}
</style>