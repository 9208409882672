<template>
  <CategoryTop :title="$t('account.title')" :description="description" />

  <div v-if="loading">
    <Loader :show="true" spinner="spinner-red.svg" />
  </div>

  <MDBRow v-else class="mt-5">
    <MDBCol cols="12" lg="4" class="mb-4" v-for="(item, i) in roleitems" :key="i"
      :class="`${item.show ? '' : 'd-none'}`">
      <Card :data="item" :linkText="item.linkText" v-if="item.show" />
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import CategoryTop from "@/components/General/CategoryTop/index.vue";
import Card from "@/components/General/Card/index.vue";
import Loader from "../General/Loader/index.vue";

import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import i18n from "@/plugins/i18n";
import TokenService from "@/services/storage.service";

import { computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
const LANG = localStorage.getItem("lang");
const isDutch = computed(() => LANG === "nl");
const isGermany = computed(() => LANG === "de");
const description = ref(i18n.global.t("account.desc"));

const store = useStore();

onMounted(async () => {
  await store.dispatch("loadGroups");
  await store.dispatch("me");
  await store.dispatch("fetchProfile");
});

const me = computed(() => store.getters.getMe);

const loading = computed(() => store.getters.getPending);
const groups = computed(() => store.getters.getGroups);
const profile = computed(() => store.getters.getProfile);
const isFirstStopGroup = computed(() => {
  const firstStopId = groups.value.filter((item) => {
    return item.gka === "FSP";
  })[0];

  return firstStopId && firstStopId.id === profile.value.group_id;
});

const roleitems = computed(() => {
  let data = [];

  if (isWholesaler.value || isWholesalerStaff.value) {
    data.push(
      ...[
        {
          name: i18n.global.t("pageName.staff"),
          link: { name: "wholesaler" },
          description: i18n.global.t("wholesaler.dashboard.description"),
          linkText: i18n.global.t("wholesaler.dashboard.title"),
          show: true,
        },
      ]
    );

    data.push(
      ...[
        {
          name: i18n.global.t("kickbackInfo.title"),
          link: { name: "rewardsOverview" },
          description: i18n.global.t("kickbackInfo.short_description"),
          linkText: i18n.global.t("buttons.toKickbackFeeInfo"),
          show: isDutch.value,
        },
        {
          name: i18n.global.t("vouchers.title"),
          link: { name: "wholesalerVouchers" },
          description: i18n.global.t("vouchers.short_description"),
          linkText: i18n.global.t("buttons.vouchers"),
          show: isDutch.value || isGermany.value,
        },
      ]
    );

  } else if (isStaff.value) {
    data.push(
      ...[
        {
          name: i18n.global.t("pageName.staff"),
          link: { name: "staff" },
          description: i18n.global.t("wholesaler.dashboard.description"),
          linkText: i18n.global.t("wholesaler.dashboard.title"),
          show: true,
        },
        {
          name: i18n.global.t("kickbackInfo.title"),
          link: { name: "rewardsOverview" },
          description: i18n.global.t("kickbackInfo.short_description"),
          linkText: i18n.global.t("buttons.toKickbackFeeInfo"),
          show: isDutch.value,
        },
        {
          name: i18n.global.t("vouchers.title"),
          link: { name: "staffVouchers" },
          description: i18n.global.t("vouchers.short_description"),
          linkText: i18n.global.t("buttons.vouchers"),
          show: isDutch.value || isGermany.value,
        },
      ]
    );
  } else if (isDealer.value) {
    data.push(
      ...[
        {
          name: i18n.global.t("account.categories.basedata.title"),
          link: { name: "stammdaten" },
          description: i18n.global.t("account.categories.basedata.desc"),
          linkText: i18n.global.t("buttons.userProfile"),
          show: TokenService.showElement("profilePage.see"),
        },
        {
          name: i18n.global.t("account.categories.pobox.title"),
          link: { name: "mailbox" },
          description: i18n.global.t("account.categories.pobox.desc"),
          linkText: i18n.global.t("buttons.mailbox"),
          show: true,
        },
        {
          name: i18n.global.t("account.categories.bankstatement.title"),
          link: { name: "pointsOverview" },
          description: i18n.global.t("account.categories.bankstatement.desc"),
          linkText: i18n.global.t("buttons.pointsList"),
          show: TokenService.showElement("accountStatement.see"),
        },
        {
          name: i18n.global.t("orders"),
          link: { name: "userOrders" },
          description: i18n.global.t("order.description"),
          linkText: i18n.global.t("buttons.userOrders"),
          show: TokenService.showElement("orderList.see"),
        },
        {
          name: i18n.global.t("pageName.favoriteProducts"),
          link: { name: "favoriteProducts" },
          description: i18n.global.t("favoriteProducts.description"),
          linkText: i18n.global.t("buttons.toFavorites"),
          show: TokenService.showElement("shopFavorites.see"),
        },
      ]
    );

    data.push(
      ...[
        {
          name: i18n.global.t("commitmentLetter.title"),
          link: { name: "commitmentLetter" },
          description: i18n.global.t("commitmentLetter.short_description"),
          linkText: i18n.global.t("buttons.toCommitmentLetter"),
          show: TokenService.showElement("commitmentLetterPage.see"),
        },
        {
          name: i18n.global.t("kickbackInfo.title"),
          link: { name: "rewardsOverview" },
          description: i18n.global.t("kickbackInfo.short_description"),
          linkText: i18n.global.t("buttons.toKickbackFeeInfo"),
          show: isDutch.value,
        },
        {
          name: i18n.global.t("about.sections.firstStop.title"),
          link: { name: "first_stop" },
          description: i18n.global.t("about.sections.firstStop.description"),
          linkText: i18n.global.t("buttons.toKickbackFeeInfo"),
          show: isFirstStopGroup.value && isDutch.value,
        },
      ]
    );
  }

  return data;
});

const isWholesaler = ref(false);
const isWholesalerStaff = ref(false);
const isStaff = ref(false);
const isDealer = ref(false);

watch(me, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isStaff.value = true;
    description.value = i18n.global.t("account.descStaff");
  }
  if (newVal && newVal.roles.find((item) => item.name == "dealer")) {
    isDealer.value = true;
  }
});
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";
</style>
