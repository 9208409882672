<template>
  <MDBCard class="container-height" :class="[
    `${user?.commitment_letter_accept && user?.intro == 1 ? 'intro__step' : ''
    }`,
  ]">
    <MDBCardBody>
      <MDBCardText class="font-12 font-md-15">
        <div>
          <b>{{ $t("tyreBox.totalAmount") }} {{ currentYear }}:</b>&nbsp;
          <span class="success-green fw-bold">
            {{
              tyresAmount ? tyresAmount["13_16"] +
                tyresAmount["17_18"] +
                tyresAmount["19_"] : 0 }}</span>
        </div>
        <div v-if="tyresAmount">
          <div>
            <b>13-16":</b>
            {{ tyresAmount["13_16"]
            }}<br />
            <b>17-18":</b>
            {{ tyresAmount["17_18"]
            }}<br />
            <b>19"+:</b>
            {{ tyresAmount["19_"] }}
          </div>
        </div>
        <div v-else>
          <div>
            <b>13-16":</b> 0<br />
            <b>17-18":</b> 0<br />
            <b>19"+:</b> 0
          </div>
        </div>
        <div v-if="tyresPerYear.length > 0">
          <div v-for="(tyres, index) in tyresPerYear" :key="index">
            <span v-if="tyres.year != currentYear">
              <b>{{ $t("wholesaler.dashboard.overview.all") }} {{ $t("contact.form.tires").toLocaleLowerCase() }} {{
                tyres.year }}:</b> {{
                  tyres.yearly_sum }}</span>
          </div>
        </div>
        <div v-else> <b>{{ $t("wholesaler.dashboard.overview.all") }} {{ $t("contact.form.tires").toLocaleLowerCase() }}
            2024:</b> 0</div>
        <div class="pt-0 p-relative">
          <b>{{ $t("tyreBox.target") }} {{ currentYear }}:&nbsp;</b><span
            v-if="bonusMultiplicator.length > 0 && checkUserGoal()">{{
              $t(`multiplicator.goals.${checkUserGoal().name}`) }} min. {{
              checkUserGoal().min_tyres }} {{ $t("tyreBox.title").toLocaleLowerCase() }}
          </span>
          <span v-else>{{ $t('multiplicator.chooseTarget') }}</span>
          <span class="info-notification cursor--pointer info-notification--position" @click="openModal()">!</span>
        </div>
        <div class="pt-0" v-if="bonusMultiplicator.length > 0 && checkUserGoal()">
          {{
            $t(`multiplicator.goals.${checkUserGoal().name}`) }}&nbsp;= <span v-html="$t(`multiplicator.multiplication`, {
            factor: checkUserGoal().factor
          })"></span>
        </div>
        <div class="pt-0">
          <b>{{ $t("tyreBox.entry") }} {{ currentYear }}:</b> {{ shopLimit?.limit }}
          <span class="text-lowercase">{{ $t("tyreBox.title") }}</span>
        </div>
      </MDBCardText>
    </MDBCardBody>
    <MDBModal v-model="modal" tabindex="-1" centered size="xl">
      <Loader :show="loading" v-if="loading" />
      <MDBCard v-else>
        <MDBCardBody>
          <MultiplicatorInfo />
        </MDBCardBody>
      </MDBCard>
    </MDBModal>
  </MDBCard>
</template>

<script setup>
import Loader from "@/components/General/Loader/index.vue";
import MultiplicatorInfo from "@/components/KickbackFee/MultiplicatorInfo.vue";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText, MDBModal
} from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import { ProfileService } from "@/services/profile.service";

const store = useStore();

const user = computed(() => store.getters.getMe);
const shopLimit = computed(() => store.getters.getTyreLimit);
const tyresAmount = computed(() => user?.value.specified_tyres_amount);
const bonusMultiplicator = computed(() => store.getters.getBonusMultiplicator);
const tyresPerYear = computed(() => store.getters.getTyresPerYear);
const loading = computed(() => store.getters.getPending);

const modal = ref(false)
const currentYear = new Date().getFullYear()

const openModal = () => {
  modal.value = true;
};
const initialData = async () => {
  const currentYear = new Date().getFullYear()
  await store.dispatch('fetchTyreLimit', currentYear)
  const bonusData = { user_id: user.value.plainId }
  await ProfileService.createBonus(bonusData);
}


const checkUserGoal = () => {
  if (bonusMultiplicator.value.length > 0) {
    const goal = bonusMultiplicator.value.find(bonus => bonus.id === user.value.bonus_multiplicator_id)
    return goal ? goal : null
  }
  return false
}
onMounted(async () => {
  await initialData()
})
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";
@import "@/styles/welcome_box.scss";
@import "@/styles/mailbox.scss";
@import "@/styles/points_overview.scss";

.container-height {
  height: 100%;
  max-height: fit-content;
}
</style>
