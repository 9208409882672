<template>
  <div class="bg-white p-4">
    <CategoryTop :title="$t('pageName.rewardsOverview')" :description="$t('kickbackInfo.description')"
      :breadcrumbs-parent-routes="['account']" content-class="px-4 mt-4" left-col="10" right-col="2">
      <template v-if="groupImage && isDealer" #right>
        <DashboardCard :border="false" class="d-flex justify-content-end">
          <template v-slot:content>
            <img :src="groupImage" />
          </template>
        </DashboardCard>
      </template>
    </CategoryTop>
    <Loader :show="loading" v-if="loading"></Loader>
    <div v-else>
      <MDBRow v-if="isDealer">
        <MDBCol xl="6" cols="12" class="pb-3" v-if="Object.keys(data).length > 0">
          <MDBTable borderless class="simple-table">
            <thead>
              <tr>
                <th class="th--height">
                  {{ $t("rewardsOverview.groupLevel") }}
                </th>
                <th class="th--height" v-html="$t('rewardsOverview.titlePoints')"></th>
                <th class="th--height">14-16"</th>
                <th class="th--height">17-18"</th>
                <th class="th--height">19-22"</th>
              </tr>
            </thead>
            <tbody v-for="(items, key, index) in data" :key="index">
              <tr>
                <td>
                  <span v-if="index == 0">{{
                    setGroupName(items[0].group_id)
                    }}</span>
                </td>
                <td>{{ items[0].name[LANG] }}</td>
                <td v-for="point in items" :key="point.id">
                  {{ point.points }}
                </td>
              </tr>
            </tbody>
          </MDBTable>
          <div>{{ $t("rewardsOverview.footerPoints") }}</div>
        </MDBCol>

        <MDBCol xl="6" cols="12" class="pb-3" v-if="Object.keys(data).length > 0">
          <MDBTable borderless class="simple-table">
            <thead>
              <tr>
                <th class="th--height">
                  {{ $t("rewardsOverview.titleKickback") }}
                </th>
                <th class="th--height">14-16"</th>
                <th class="th--height">17-18"</th>
                <th class="th--height">19-22"</th>
              </tr>
            </thead>
            <tbody v-for="(items, key, index) in data" :key="index">
              <tr>
                <td>{{ items[0].name[LANG] }}</td>
                <td v-for="point in items" :key="point.id">
                  {{ point.kickback_fee.toFixed(2) }} &euro;
                </td>
              </tr>
            </tbody>
          </MDBTable>
          <div>{{ $t("rewardsOverview.kickbackPoints") }}</div>
        </MDBCol>
      </MDBRow>
      <div v-else>
        <MDBRow>
          <MDBCol xl="6" cols="12" class="pb-3">
            <MDBTable borderless class="simple-table" v-for="(wholesalerData, index) in wholesalerPointsData"
              :key="index">
              <thead>
                <tr>
                  <th class="th--height">
                    {{ $t("rewardsOverview.groupLevel") }}
                  </th>
                  <th class="th--height" v-html="$t('rewardsOverview.titlePoints')"></th>
                  <th class="th--height">14-16"</th>
                  <th class="th--height">17-18"</th>
                  <th class="th--height">19-22"</th>
                </tr>
              </thead>
              <tbody v-for="(items, key, index) in wholesalerData" :key="index">
                <tr>
                  <td class="td--width">
                    <span v-if="index == 0">{{
                      setGroupName(items[0].group_id)
                      }}</span>
                  </td>
                  <td>{{ items[0].name[LANG] }}</td>
                  <td v-for="point in items" :key="point.id">
                    {{ point.points }}
                  </td>
                </tr>
              </tbody>
            </MDBTable>
            <div>{{ $t("rewardsOverview.footerPoints") }}</div>
          </MDBCol>
          <MDBCol xl="6" cols="12" class="pb-3">
            <MDBTable borderless class="simple-table" v-for="(wholesalerData, index) in wholesalerPointsData"
              :key="index">
              <thead>
                <tr>
                  <th class="th--height">
                    {{ $t("rewardsOverview.titleKickback") }}
                  </th>
                  <th class="th--height">14-16"</th>
                  <th class="th--height">17-18"</th>
                  <th class="th--height">19-22"</th>
                </tr>
              </thead>
              <tbody v-for="(items, key, index) in wholesalerData" :key="index">
                <tr>
                  <td>{{ items[0].name[LANG] }}</td>
                  <td v-for="point in items" :key="point.id">
                    {{ point.kickback_fee.toFixed(2) }} &euro;
                  </td>
                </tr>
              </tbody>
            </MDBTable>
            <div>{{ $t("rewardsOverview.kickbackPoints") }}</div>
          </MDBCol>
        </MDBRow>
      </div>
      <MultiplicatorInfo />
    </div>
  </div>
</template>

<script setup>
import { MDBCol, MDBRow, MDBTable } from "mdb-vue-ui-kit";
import CategoryTop from "@/components/General/CategoryTop/index.vue";
import DashboardCard from "@/components/User/Wholesaler/DashboardCard.vue";
import MultiplicatorInfo from "@/components/KickbackFee/MultiplicatorInfo.vue";
import Loader from "@/components/General/Loader/index";
import { useStore } from "vuex";
import { computed, ref, watch, onMounted } from "vue";
import { ProfileService } from "@/services/profile.service";

const LANG = localStorage.getItem("lang");
const store = useStore();

const data = computed(() => store.getters.getKickbackInfo);
const groups = computed(() => store.getters.getGroups);
const loading = computed(() => store.getters.getPending);
const user = computed(() => store.getters.getMe);
const meIsPending = computed(() => store.getters.getMeIsPending);
const groupImage = ref(null);
const isDealer = ref(false);
const wholesalerPointsData = ref([]);

const setGroupName = (id) => {
  const output = groups.value.filter((item) => {
    return item.id === id;
  });

  groupImage.value =
    output[0].gka === "FSP" || output[0].gka === "Profile"
      ? require(`@/assets/img/${output[0].gka}_logo.png`)
      : null;

  return output[0].group;
};

onMounted(async () => {
  if (!meIsPending.value) {
    await store.dispatch("me");
  }
  await store.dispatch("loadGroups");
  if (isDealer.value) {
    await store.dispatch("loadKickbackInfo", 0);
  } else {
    for (const property in groups.value) {
      const response = await ProfileService.showKickbackInfo(
        groups.value[property].id
      );

      if (Object.keys(response.data).length > 0) {
        wholesalerPointsData.value.push(response.data);
      }
    }
  }

  const currentYear = new Date().getFullYear()
  await store.dispatch('fetchTyreLimit', currentYear)
});

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "dealer")) {
    isDealer.value = true;
  }
});
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
@import "@/styles/points_overview.scss";

.simple-table {
  width: 100%;
  border-spacing: 1px;
  border-collapse: separate;
  padding-top: 30px;

  td,
  th {
    padding: 5px;
    text-align: left;
  }

  th {
    background: $gray;
    color: $backgroundGray;
  }
}

.simple-table>tbody:nth-of-type(odd)>* {
  background-color: $secondaryGray;
}

.simple-table>tbody:nth-of-type(even)>* {
  background-color: $backgroundGray;
}

.th--height {
  height: 56px;
  vertical-align: top;
}

.td--width {
  width: 150px;
}
</style>
